/* javascript-obfuscator:disable */
import { toggleAppLoader } from './src/components/appLoader';
import { container, timeout } from './src/global';
/* javascript-obfuscator:disable */

const home = () => {
  if (localStorage.getItem('userID')) {
    window.location.replace('/share.html?id=' + localStorage.getItem('userID'));
    return;
  }
  toggleAppLoader();
  setTimeout(() => {
    toggleAppLoader();
  }, 1000);

  const showUserNamePage = async () => {
    toggleAppLoader();
    let userNameHtml = (await import('./src/htmlIncludes/userName.html?raw'))
      .default;
    let { userNameJS } = await import('./src/htmlIncludes/userName.js');
    await timeout(500);
    container.innerHTML = userNameHtml; //Updated HTML
    userNameJS(); //Added JS
    toggleAppLoader();
  };

  document.getElementById('showUserNamePage').onclick = () => {
    showUserNamePage();
  };
};

home();

